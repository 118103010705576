import React, { useState } from 'react'
import baseline from '../assets/baseline.png'
import heart from '../assets/heart.svg'
import reportProblem from '../assets/report-problem.svg'
import miniTrail from '../assets/miniTrail.svg'

export const SupportButton = () => {
  const [visibility, setVisibility] = useState(false);

  const handleVisibility = () => setVisibility(!visibility);
  return (
    <div className="support-btn-container">
      <div className={visibility ? "support-extra-buttons-container" : "support-extra-buttons-container-hidden"}>
        <a href="https://getbaseline.app/"><button className="support-extra-button support-extra-button-margin connect-baseline-btn"><img src={heart} alt="heart" className="support-extra-button-icon" /> <span>CONNECT</span> <img src={baseline} alt="baseline icon" className="baseline-icon" /></button></a>
        <a href="https://discord.gg/EytgW539bY"><button className="support-extra-button support-extra-button-margin"><img src={reportProblem} alt="report problem" className="support-extra-button-icon" /> <span>REPORT A PROBLEM</span> </button></a>
        <a href="https://www.metamountaineers.xyz/how-to-earn-trail"><button className="support-extra-button support-extra-earn-trail-button"> <img src={miniTrail} alt="mini trail" className="support-extra-button-icon" /> <span>HOW TO EARN TRAIL</span> </button></a>
      </div>
      <button className="support-btn-green" onClick={handleVisibility}>
        <span className="support-btn">?</span>
      </button>
    </div>
  )
}
